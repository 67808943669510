import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// import rootReducers from './rootReducers';
import createReducer from "./rootReducers";
import rootSaga from './rootSaga';
import { createWrapper } from 'next-redux-wrapper';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'


const initStore = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware(),
      sagaMiddleware,
      // createStateSyncMiddleware() - Removed as caused problems with the cart
    ],
    preloadedState,
  })

  store.asyncReducers = {};

  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
    return store;
  }
  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

  initMessageListener(store);
  
  // sagaMiddleware.run(rootSaga)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducers', () => store.replaceReducer(rootReducers))
  }

  return store;
};



function createSagaInjector(runSaga, rootSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = key => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
      // We won't run saga if it is already injected
      if (isInjected(key)) return;

      // Sagas return task when they executed, which can be used
      // to cancel them
      const task = runSaga(saga);

      // Save the task if we want to cancel it in the future
      injectedSagas.set(key, task);
  };

  // Inject the root saga as it a staticlly loaded file,
  injectSaga('root', rootSaga);

  return injectSaga;
}


export const wrapper = createWrapper(initStore,
  //  {debug: true}
   );